import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../CSS/Agenda.css';
import Loader from '../Components/Loading/Loading';
import { addDays, format, startOfWeek, endOfWeek } from 'date-fns';
import { toZonedTime, format as formatTZ } from 'date-fns-tz';

interface Course {
    id?: number;
    date: string;
    time: string;
    duration: number;
    title: string;
    description: string;
    creator: string;
}

const Agenda: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [courses, setCourses] = useState<Course[]>([]);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
    const [newCourse, setNewCourse] = useState<Course>({
        date: '',
        time: '',
        duration: 1,
        title: '',
        description: '',
        creator: ''
    });
    const [currentWeek, setCurrentWeek] = useState<Date>(new Date());

    useEffect(() => {
        const fetchCourses = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get('https://alexandre-bernardini.fr/back_Eclat/agenda', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    console.log('Cours récupérés:', response.data);
                    setCourses(response.data);
                } catch (error) {
                    console.error('Erreur lors de la récupération des cours :', error);
                }
            } else {
                console.error('Token non disponible');
            }
            setLoading(false);
        };

        fetchCourses();

        const pseudo = localStorage.getItem('userData');
        if (pseudo) {
            setNewCourse((prevCourse) => ({
                ...prevCourse,
                creator: pseudo,
            }));
        }
    }, []);

    const hours = [
        '08:00 - 09:00',
        '09:00 - 10:00',
        '10:00 - 11:00',
        '11:00 - 12:00',
        '12:00 - 13:00',
        '13:00 - 14:00',
        '14:00 - 15:00',
        '15:00 - 16:00',
        '16:00 - 17:00',
        '17:00 - 18:00',
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setNewCourse({ ...newCourse, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if (token) {
            try {
                // Envoyer les données du cours au backend
                const response = await axios.post('https://alexandre-bernardini.fr/back_Eclat/agenda', newCourse, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log('Cours ajouté:', response.data);
                // Ajouter le cours à l'état local avec l'ID du cours ajouté
                setCourses([...courses, { ...newCourse, id: response.data.insertId }]);
                setNewCourse({ date: '', time: '', duration: 1, title: '', description: '', creator: newCourse.creator });
                setShowForm(false);
            } catch (error) {
                console.error('Erreur lors de l\'ajout du cours :', error);
            }
        } else {
            console.error('Token non disponible');
        }
    };

    const handlePrevWeek = () => {
        setCurrentWeek((prevDate) => addDays(prevDate, -7));
    };

    const handleNextWeek = () => {
        setCurrentWeek((prevDate) => addDays(prevDate, 7));
    };

    const startOfWeekDate = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(currentWeek, { weekStartsOn: 1 });
    const daysOfWeek: Date[] = [];
    for (let i = 0; i < 7; i++) {
        daysOfWeek.push(addDays(startOfWeekDate, i));
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const groupedCourses = courses.reduce((acc, course) => {
        const courseDate = formatTZ(toZonedTime(new Date(course.date), timezone), 'yyyy-MM-dd', { timeZone: timezone });
        (acc[courseDate] = acc[courseDate] || []).push(course);
        return acc;
    }, {} as Record<string, Course[]>);

    console.log('Grouped Courses:', groupedCourses);

    const openModal = (course: Course) => {
        setSelectedCourse(course);
        const modal = document.getElementById("myModal");
        if (modal) {
            modal.style.display = "block";
        }
    };

    const closeModal = () => {
        setSelectedCourse(null);
        const modal = document.getElementById("myModal");
        if (modal) {
            modal.style.display = "none";
        }
    };

    return (
        <div className='agenda-body'>
            <button onClick={() => setShowForm(true)}>Créer un cours</button>
            {showForm && (
                <form className="course-form" onSubmit={handleSubmit}>
                    <label>
                        Date:
                        <input type="date" name="date" value={newCourse.date} onChange={handleInputChange} required />
                    </label>
                    <label>
                        Heure:
                        <select name="time" value={newCourse.time} onChange={handleInputChange} required>
                            <option value="">Sélectionner une heure</option>
                            {hours.map((hour) => (
                                <option key={hour} value={hour}>{hour}</option>
                            ))}
                        </select>
                    </label>
                    <label>
                        Durée (en heures):
                        <input type="number" name="duration" value={newCourse.duration} onChange={handleInputChange} min="1" max="10" required />
                    </label>
                    <label>
                        Titre:
                        <input type="text" name="title" value={newCourse.title} onChange={handleInputChange} required />
                    </label>
                    <label>
                        Description:
                        <textarea name="description" value={newCourse.description} onChange={handleInputChange} required />
                    </label>
                    <button className='button-add' type="submit">Ajouter le cours</button>
                    <button type="button" onClick={() => setShowForm(false)}>Annuler</button>
                </form>
            )}
            <div className="week-navigation">
                <button onClick={handlePrevWeek}>Semaine précédente</button>
                <span>
                    {format(startOfWeekDate, 'dd/MM/yyyy')} - {format(endOfWeekDate, 'dd/MM/yyyy')}
                </span>
                <button onClick={handleNextWeek}>Semaine suivante</button>
            </div>
            <table className="schedule-table">
                <thead>
                    <tr>
                        <th>Heure</th>
                        {daysOfWeek.map((day) => (
                            <th key={day.toString()}>{format(day, 'EEEE dd/MM')}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {hours.map((hour) => (
                        <tr key={hour}>
                            <td>{hour}</td>
                            {daysOfWeek.map((day) => {
                                const dateStr = format(day, 'yyyy-MM-dd');
                                const coursesForDay = groupedCourses[dateStr] || [];
                                const courseForHour = coursesForDay.find(course => course.time.split(' - ')[0] === hour.split(' - ')[0]);

                                return (
                                    <td
                                        key={dateStr + hour}
                                        className={courseForHour ? 'course-cell' : ''}
                                        onClick={() => courseForHour && openModal(courseForHour)}
                                    >
                                        {courseForHour && (
                                            <>
                                                <div className="course-title">{courseForHour.title}</div>
                                                <div className="course-creator">Créé par : {courseForHour.creator}</div>
                                            </>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            {selectedCourse && (
                <div id="myModal" className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>{selectedCourse.title}</h2>
                        <p>{selectedCourse.description}</p>
                        <p><strong>Créé par :</strong> {selectedCourse.creator}</p>
                        <p><strong>Durée :</strong> {selectedCourse.duration} heure(s)</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Agenda;
