import React, { useState, useEffect } from 'react';
import '../CSS/Profile.css';
import axios from 'axios';
import Loader from '../Components/Loading/Loading';

interface UserProfile {
    pseudo: string;
    prenom: string;
    nom: string;
    email: string;
    age: string;
    telephone: string;
}

const Profile: React.FC = () => {
    const [user, setUser] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const [formData, setFormData] = useState<UserProfile>({
        pseudo: '',
        prenom: '',
        nom: '',
        email: '',
        age: '',
        telephone: ''
    });

    useEffect(() => {
        const fetchUserProfile = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get('https://alexandre-bernardini.fr/back_Eclat/profil', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUser(response.data);
                    setFormData(response.data);
                } catch (error) {
                    console.error('Erreur lors de la récupération des informations de profil :', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSave = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        if (token) {
            try {
                await axios.put('https://alexandre-bernardini.fr/back_Eclat/profil', formData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUser(formData);
                setEditing(false);
            } catch (error) {
                console.error('Erreur lors de la mise à jour des informations de profil :', error);
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (!user) {
        return (
            <div className='body-profile'>
                <h1>Profile</h1>
                <p>Aucune information de profil disponible.</p>
            </div>
        );
    }

    return (
        <div className='body-profile'>
            <h1>Profile</h1>
            <div className="profile-container">
                <div className="profile-info">
                    <h2>{user.pseudo}</h2>
                    {editing ? (
                        <>
                            <div className="profile-detail">
                                <strong>Prénom:</strong>
                                <input
                                    type="text"
                                    name="prenom"
                                    value={formData.prenom}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="profile-detail">
                                <strong>Nom:</strong>
                                <input
                                    type="text"
                                    name="nom"
                                    value={formData.nom}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="profile-detail">
                                <strong>Email:</strong>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="profile-detail">
                                <strong>Âge:</strong>
                                <input
                                    type="text"
                                    name="age"
                                    value={formData.age}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="profile-detail">
                                <strong>Téléphone:</strong>
                                <input
                                    type="text"
                                    name="telephone"
                                    value={formData.telephone}
                                    onChange={handleChange}
                                />
                            </div>
                            <button onClick={handleSave}>Enregistrer</button>
                            <button onClick={() => setEditing(false)}>Annuler</button>
                        </>
                    ) : (
                        <>
                            <div className="profile-detail">
                                <strong>Prénom:</strong>
                                <span>{user.prenom}</span>
                            </div>
                            <div className="profile-detail">
                                <strong>Nom:</strong>
                                <span>{user.nom}</span>
                            </div>
                            <div className="profile-detail">
                                <strong>Email:</strong>
                                <span>{user.email}</span>
                            </div>
                            <div className="profile-detail">
                                <strong>Âge:</strong>
                                <span>{user.age}</span>
                            </div>
                            <div className="profile-detail">
                                <strong>Téléphone:</strong>
                                <span>{user.telephone}</span>
                            </div>
                            <button onClick={() => setEditing(true)}>Modifier</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Profile;
