import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from '../../Image/LogoECLATPNG.png';
import {jwtDecode } from 'jwt-decode';

interface DecodedToken {
    pseudo: string;
}

const Navbar: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [pseudo, setPseudo] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode<DecodedToken>(token);
                setPseudo(decodedToken.pseudo);
                setIsLoggedIn(true);
            } catch (error) {
                console.error('Erreur lors du décodage du token:', error);
            }
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setPseudo('');
        navigate('/');
        window.location.reload(); // Rafraîchir la page après la déconnexion
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const renderNavItems = () => {
        const commonNavItems = (
            <>
                <a href="/" onClick={closeMenu}>Accueil</a>
                <a href="/Offre" onClick={closeMenu}>Offre</a>
                <a href="/Actualite" onClick={closeMenu}>Actualité</a>
                <a href="/Don" onClick={closeMenu}>Don</a>
                {!isLoggedIn && <a href="/Connection" onClick={closeMenu}>Profil</a>}
            </>
        );

        const loggedInNavItems = (
            <>
                <a href="/Agenda" onClick={closeMenu}>Agenda</a>
                <a href="/Forum" onClick={closeMenu}>Forum</a>
            </>
        );

        const rightNavItems = isLoggedIn && (
            <div className="dropdown align-right">
                <button className="dropbtn" onClick={toggleDropdown}>
                    {pseudo}
                </button>
                {dropdownOpen && (
                    <div className="dropdown-content">
                        <a href="/profile" onClick={closeMenu}>Profil</a>
                        <a onClick={() => { handleLogout(); closeMenu(); }}>Déconnexion</a>
                    </div>
                )}
            </div>
        );

        return (
            <>
                {commonNavItems}
                {isLoggedIn && loggedInNavItems}
                {rightNavItems}
            </>
        );
    };

    return (
        <nav className='navbar' id='navbarTop'>
            <img className='logo' src={logo} alt="logo" />
            <div className={`navbar-items ${menuOpen ? 'show' : ''}`}>
                <div className="close-menu" onClick={closeMenu}>&times;</div>
                {renderNavItems()}
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                &#9776;
            </div>
        </nav>
    );
};

export default Navbar;
