import '../CSS/Connection.css';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthIndicator from '../Components/GuidePassword/PasswordStrengthIndicator';

const Connection: React.FC = () => {
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const [formData, setFormData] = useState({
        pseudo: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        age: '',
        telephone: ''
    });
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleSignUp = async () => {
        try {
            const response = await axios.post('https://alexandre-bernardini.fr/back_Eclat/inscription', {
                pseudo: formData.pseudo,
                prenom: formData.firstName,
                nom: formData.lastName,
                age: formData.age,
                email: formData.email,
                mot_de_passe: formData.password,
                telephone: formData.telephone
            });
            console.log(response.data); // Assuming server responds with some data
            // Réinitialiser les champs après l'envoi
            setFormData({
                pseudo: '',
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                age: '',
                telephone: ''
            });
            navigate('/');
            setErrorMessage('');
        } catch (error) {
            console.error('Error sending data to server:', error);
        }
    };

    const handleSignIn = async () => {
        try {
            const response = await axios.post('https://alexandre-bernardini.fr/back_Eclat/connexion', {
                pseudo: formData.pseudo,
                mot_de_passe: formData.password
            });

            console.log(response.data); // Assuming server responds with some data
            console.log(formData.pseudo, formData.password);
            if (response.data.success) {
                // Stocker le token JWT dans le localStorage
                localStorage.setItem('token', response.data.token);
                navigate('/');
                window.location.reload(); // Rafraîchir la page après la connexion réussie
            } else {
                alert('Nom d\'utilisateur ou mot de passe incorrect.');
            }

            // Réinitialiser les champs après l'envoi
            setFormData({
                ...formData,
                pseudo: '',
                password: ''
            });
        } catch (error) {
            console.error('Error sending data to server:', error);
        }
    };

    const toggleForm = () => {
        setShowAdditionalFields(false);
        setShowSignIn(!showSignIn); // Basculer l'affichage du formulaire de connexion
        // Réinitialiser les champs lorsque vous basculez entre les formulaires
        setFormData({
            pseudo: '',
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            age: '',
            telephone: ''
        });
        setErrorMessage('');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleNextStep = () => {
        const { pseudo, email, password } = formData;
        if (!pseudo || !email || !password) {
            setErrorMessage('Veuillez remplir tous les champs.');
        } else {
            setShowAdditionalFields(true);
            setErrorMessage('');
        }
    };

    return (
        <div className='Component'>
            {!showSignIn ? (
                !showAdditionalFields ? (
                    <form>
                        <h2>Inscription - Étape 1</h2>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="pseudo"
                            placeholder="Pseudo"
                            value={formData.pseudo}
                            onChange={handleChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Mot de passe"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <PasswordStrengthIndicator password={formData.password} />
                        <button 
                            type="button" 
                            onClick={handleNextStep}
                        >
                            Suivant
                        </button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <div className='Contact-formulaire-footer'>
                            <p>Vous avez déjà un compte ? <button type="button" onClick={toggleForm}>Se connecter</button></p>
                        </div>
                    </form>
                ) : (
                    <form>
                        <h2>Inscription - Étape 2</h2>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="Prénom"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Nom"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                        <input
                            type="number"
                            name="age"
                            placeholder="Âge"
                            value={formData.age}
                            onChange={handleChange}
                        />
                        <input
                            type="tel"
                            name="telephone"
                            placeholder="Téléphone"
                            value={formData.telephone}
                            onChange={handleChange}
                        />
                        <button type="button" onClick={handleSignUp}>S'inscrire</button>
                        <button type="button" onClick={() => setShowAdditionalFields(false)}>Revenir à l'étape 1</button>
                        <div className='Contact-formulaire-footer'>
                            <p>Vous avez déjà un compte ? <button type="button" onClick={toggleForm}>Se connecter</button></p>
                        </div>
                    </form>
                )
            ) : (
                <form>
                    <h2>Connexion</h2>
                    <div className='Contact-formulaire-connexion'>
                        <div className='Contact-formulaire-Nom-connexion'>
                            <input
                                type='text'
                                name="pseudo"
                                placeholder="Pseudo"
                                value={formData.pseudo}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='Contact-formulaire-email-connexion'>
                            <input
                                type='password'
                                name="password"
                                placeholder="Mot de passe"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                        <button className='button-connect' type="button" onClick={handleSignIn}>Se connecter</button>
                    </div>
                    <div className='Contact-formulaire-footer'>
                        <p>Vous n'avez pas de compte ? <button type="button" onClick={toggleForm}>S'inscrire</button></p>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Connection;
