import React, { useState, useEffect } from 'react';
import '../CSS/Blog.css';
import axios from 'axios';
import Loader from '../Components/Loading/Loading';
import PublishButton from '../Components/PublishButton/PublishButton';
import { jwtDecode } from 'jwt-decode';

interface BlogPost {
    id: number;
    content: string;
    author: string;
    date: string;
    imageUrl?: string;
}

const Blog: React.FC = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [content, setContent] = useState('');
    const [author, setAuthor] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode<{ pseudo: string }>(token);
            setAuthor(decodedToken.pseudo);
        }
        fetchPosts();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 2 secondes

        return () => clearTimeout(timer);
    }, []);

    const fetchPosts = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://alexandre-bernardini.fr/back_Eclat/blogposts', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPosts(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des publications de blog :', error);
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!content && !image) {
            setError('Le contenu ou une image est obligatoire');
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('content', content);
        formData.append('author', author);
        if (image) {
            formData.append('image', image);
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('https://alexandre-bernardini.fr/back_Eclat/blogpost', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const newPost: BlogPost = {
                id: response.data.id,
                content,
                author,
                date: new Date().toLocaleString(),
                imageUrl: response.data.imageUrl,
            };

            setPosts([newPost, ...posts]); // Ajoute le nouveau post en haut de la liste
            setContent('');
            setImage(null);
            setError('');
        } catch (error) {
            console.error('Erreur lors de la création de la publication de blog :', error);
            setError('Une erreur est survenue lors de la création de la publication');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="blog-container">
            <h1>Blog</h1>
            <div className="posts">
                {posts.map((post) => (
                    <div key={post.id} className="post">
                        <p className="post-content">{post.content}</p>
                        {post.imageUrl && <img src={`https://alexandre-bernardini.fr/back_Eclat/${post.imageUrl}`} alt="Post" className="post-image" />}
                        <p className="post-meta"><strong>{post.author}</strong> - {post.date}</p>
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="post-form">
                <textarea
                    placeholder="Quoi de neuf ?"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                ></textarea>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <PublishButton onClick={handleSubmit} disabled={isSubmitting} />
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default Blog;
