import React from 'react';
import './PasswordStrengthIndicator.css';

interface PasswordStrengthIndicatorProps {
    password: string;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ password }) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;

    return (
        <div className="password-strength-indicator">
            <p className={hasUpperCase ? 'valid' : 'invalid'}>Au moins une majuscule</p>
            <p className={hasLowerCase ? 'valid' : 'invalid'}>Au moins une minuscule</p>
            <p className={hasNumber ? 'valid' : 'invalid'}>Au moins un chiffre</p>
            <p className={hasMinLength ? 'valid' : 'invalid'}>Au moins 8 caractères</p>
        </div>
    );
};

export default PasswordStrengthIndicator;
