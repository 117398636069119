import '../CSS/Offre.css'
import React, {useState, useEffect} from 'react'
import Loader from '../Components/Loading/Loading'; // Assurez-vous que le chemin est correct

const Home: React.FC = () => {
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 secondes

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

    return (
        <div className='body'>
            <h2>DECOUVREZ NOS OFFRES !</h2>
        <div className='content'>
            {/* 1er offre */}
            <div className="page-offre-content-une">
                <div className="page-offre-body-une">
                    <h3>Offre Gratuit</h3>
                    <ul>
                        <li>
                            <p>Un profil de base sur le réseau social professionnel</p>
                        </li>
                        <li>
                            <p>Accès restreint au marketplace intégré</p>
                        </li>
                        <li>
                            <p>Lecture seulement dans la communauté en ligne</p>
                        </li>
                        <li>
                            <p>Accès limité aux annonces et opportunités</p>
                        </li>
                        <li>
                            <p>Support minimal FAQ / E-mail</p>
                        </li>
                    </ul>
                <button className='button-of'>Disponible a l'inscription</button>
                </div>                
            </div>

            {/* 2eme offre */}
            <div className="page-offre-content-deux">
                <div className="page-offre-body-deux">
                    <h3>Essentiel</h3>
                    <ul>
                        <li>
                            <p>Accès illimité au réseau social professionnel</p>
                        </li>
                        <li>
                            <p>Le marketplace intégré</p>
                        </li>
                        <li>
                            <p>La communauté en ligne</p>
                        </li>
                        <li>
                            <p>Ressources offertes : kits de démarrages</p>
                        </li>
                        <li>
                            <p>Les annonces et opportunités</p>
                        </li>
                        <li>
                            <p>Accès aux dons de matériaux</p>
                        </li>
                        <li>
                            <p>Support Standard</p>
                        </li>
                    </ul>
                    <button className='button-of'>Je teste pour 9.99€ / mois</button>
                </div>
            </div>

            {/* 3eme offre */}
            <div className="page-offre-content-trois">
                <div className="page-offre-body-trois">
                    <h3>Premium</h3>
                    <div className='page-offre-body-trois-ul'>
                        <ul>
                            <li>
                                <p>Accès illimité au réseau social professionnel</p>
                            </li>
                            <li>
                                <p>Webinaires exclusifs et sessions de formation</p>
                            </li>
                            <li>
                                <p>Les outils et services exclusifs pour augmenter votre visibilité</p>
                            </li>
                            <li>
                                <p>Support Prioritaire</p>
                            </li>
                            <li>
                                <p>La communauté en ligne</p>
                            </li>
                            <li>
                                <p>Le marketplace intégré</p>
                            </li>
                        </ul>
                    </div>
                    <button className='button-of'>Je teste pour 29.99€ / mois</button>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Home;