import React from 'react';
import '../CSS/Don.css'

const Don: React.FC = () => {
    return (
        <div className="don-container">
            <h1>Don</h1>
            <p>Cette fonctionnalité sera disponible dans une prochaine version. Merci de votre patience.</p>
        </div>
    );
};

export default Don;
