import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/Home.css';
import imageAcceuil from '../Image/image3D.jpg';
import imageArt from '../Image/LogoArt.png';
import imageBrou from '../Image/Brouillami.png';
import image5 from '../Image/Logoartisan5.png';
import imageMarm from '../Image/Marmotte.png';
import imageOrdi from '../Image/ordi.png';
import imagePresent from '../Image/present.png';
import avis1 from '../Image/avis1.png';
import avis2 from '../Image/avis2.png';
import avis3 from '../Image/avis3.png';
import Calend from '../Image/Calendrier.png';
import { FaArrowUp } from "react-icons/fa";
import Loader from '../Components/Loading/Loading'; // Assurez-vous que le chemin est correct

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 secondes

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const scrollToPlans = () => {
  const element = document.getElementById('plans');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const navigateToOffers = () => {
  navigate('/Offre');
};

  return (
    <div className='body'>
      <div className='accueil-component'>
        <div className='accueil-text'>
          <div className='acceuil-text-component'>
            <h2>Nous aidons<br />
              les artisans créateurs<br />
              dans le développement<br />
              de leur activité.</h2>
            <p>Notre plateforme tout-en-une est là pour vous aider à réussir dans votre activité d'artisan<br />
              créateur. Votre satisfaction est notre objectif principal. <br />
              Êtes-vous prêt à démarrer cette aventure avec nous ?</p>
            <div className='accueil-button'>
              <button className='acceuil-button-try' onClick={scrollToPlans}>Essai gratuit / 30 jour</button>
              <button className='acceuil-button-demo'>Programmez une démo</button>
            </div>
          </div>
          <div className='accueil-image'>
            <img src={imageAcceuil}></img>
          </div>
        </div>
      </div>

      <div className='artisans'>
        <h3>Les artisans créateurs nous font confiance</h3>
        <div className='artisans-img'>
          <img src={imageArt}></img>
          <img src={imageBrou}></img>
          <img src={image5}></img>
          <img src={imageMarm}></img>
        </div>
      </div>

      <div className='info'>
        <div className='info-first'>
          <div className='info-first-img'>
            <img src={imageOrdi}></img>
          </div>
          <div className='info-first-text'>
            <h3>Accédez à toutes les informations et ressources<br />essentielles sur une seule plateforme, pour les<br /> artisans créateurs</h3>
            <p>Vous bénéficierez d'un ensemble de services comprenant des formations,<br />
              un réseau social professionnel, des webinaires, la possibilité d’accéder à des<br />
              dons de matériaux, de rester informé sur les actualités des artisans créateurs<br />
              ainsi qu'une boutique en ligne dédiée à l'artisanat.</p>
          </div>
        </div>
      </div>
      <div className='info2'>
        <div className='info-second'>
          <div className='info-second-img'>
            <img src={imagePresent}></img>
          </div>
          <div className='info-second-text'>
            <h3>Explorez nos formations spécialisées pour<br />
              développer vos compétences et booster<br />
              vos ventes</h3>
            <p>Découvrez nos formations pour maîtriser la gestion financière et administrative,<br />
              optimiser la mise en avant de vos produits, fixer les prix de manière stratégique,<br />
              établir des collaborations fructueuses, et dynamiser votre présence sur les<br />
              réseaux sociaux. Transformez vos compétences et propulsez votre activité<br />
              artisanale vers le succès.</p>
          </div>
        </div>
      </div>

      <div className='avis'>
        <div className='avis-content'>
          <div className='avis-title'>
            <h1>Ce que les artisans créateurs disent de nous</h1>
          </div>
          <div className='avis-cards'>
            <div className='avis-card'>
              <img src={avis1} alt="Martin RODRIGUEZ" className='avis-image' />
              <h3>Martin RODRIGUEZ</h3>
              <p>Céramiste</p>
              <p>Depuis que j'ai rejoint Éclat, je suis toujours à jour avec les dernières actualités du monde artisanal. Grâce à cette veille constante, j'ai pu adapter mes créations et participer à des événements qui ont boosté ma visibilité.</p>
              <div className='avis-rating'>
                <span>★★★★★</span>
              </div>
            </div>
            <div className='avis-card'>
              <img src={avis2} alt="Judith BLANC" className='avis-image' />
              <h3>Judith BLANC</h3>
              <p>Verrier</p>
              <p>Les formations sur Éclat sont fantastiques ! J'ai appris à optimiser ma présence sur les réseaux sociaux et à mieux gérer mes finances.</p>
              <div className='avis-rating'>
                <span>★★★★★</span>
              </div>
            </div>
            <div className='avis-card'>
              <img src={avis3} alt="Matthieu DURAND" className='avis-image' />
              <h3>Matthieu DURAND</h3>
              <p>Bijoutier</p>
              <p>Éclat m'a vraiment aidé à structurer mon activité. Grâce aux formations en gestion financière et en marketing, j'ai pu augmenter mes ventes de 30% en seulement trois mois.</p>
              <div className='avis-rating'>
                <span>★★★★★</span>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div id='plans' className='plans'>
            <h1>Choisissez votre plan</h1>
            <div className='plans-container'>
                <div className='plan-card'>
                    <h2>0,00€</h2>
                    <h3>GRATUITE</h3>
                    <p>Vous aurez accès aux offres basiques de la plateforme :</p>
                    <ul>
                        <li>Un profil de base sur le réseau social professionnel</li>
                        <li>Accès restreint à la boutique intégré</li>
                        <li>Lecture seulement dans la communauté en ligne</li>
                        <li>Accès limité aux annonces et opportunités</li>
                        <li>Support minimal (FAQ / E-mail)</li>
                    </ul>
                    <button className='discover-button' onClick={navigateToOffers}>DÉCOUVRIR</button>
                </div>
                <div className='plan-card popular'>
                    <div className='popular-badge'>POPULAIRE</div>
                    <h2>9,99€</h2>
                    <h3>ESSENTIEL</h3>
                    <p>Vous aurez accès aux éléments essentiels de la plateforme :</p>
                    <ul>
                        <li>Accès illimité au réseau social professionnel</li>
                        <li>Le boutique intégré</li>
                        <li>La communauté en ligne</li>
                        <li>Ressources offertes : kits de démarrages</li>
                        <li>Les annonces et opportunités</li>
                        <li>Accès aux dons de matériaux</li>
                        <li>Support Standard</li>
                    </ul>
                    <button className='discover-button' onClick={navigateToOffers}>DÉCOUVRIR</button>
                </div>
                <div className='plan-card'>
                    <h2>29,99€</h2>
                    <h3>PREMIUM</h3>
                    <p>Vous aurez accès à l'ensemble des fonctionnalités de la plateforme :</p>
                    <ul>
                        <li>Accès illimité au réseau social professionnel</li>
                        <li>Le boutique intégré (+ analyses des performances)</li>
                        <li>La communauté en ligne</li>
                        <li>Webinaires exclusifs et sessions de formation</li>
                        <li>Les annonces et opportunités</li>
                        <li>Les outils et services exclusifs pour augmenter votre visibilité</li>
                        <li>Accès aux dons de matériaux</li>
                        <li>Support Prioritaire</li>
                    </ul>
                    <button className='discover-button' onClick={navigateToOffers}>DÉCOUVRIR</button>
                </div>
            </div>
        </div>

        <div className='needs'>
            <div className='needs-content'>
                <div className='needs-text'>
                    <h2>Et si on discutait ensemble de<br /> vos besoins ?</h2>
                    <ul>
                        <li>Prenez rendez-vous</li>
                        <li>On vous présente la plateforme et on vous</li>
                        <li>Propose l'offre adaptée en fonction de vos besoins</li>
                        <li>Puis, profitez de tous les avantages d'ÉCLAT</li>
                    </ul>
                </div>
                <div className='needs-calendar'>
                    <img src={Calend} alt='Calendar' />
                </div>
            </div>
        </div>
        <div className='footer'>
            <button className='scroll-to-top' onClick={scrollToTop}>
                <FaArrowUp />
            </button>
        </div>
    </div>
  );
};

export default Home;
