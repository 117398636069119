import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Navbar from './Components/NavBar/navbar';
import Home from './Page/Home';
import './App.css';
import Connection from './Page/Conncection';
import Offre from './Page/Offre';
import Blog from './Page/Blog'
import Profile from './Page/Profile';
import Agenda from './Page/Agenda';
import Don from './Page/Don';
import Actu from './Page/Actu';

const App: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <div id="top"></div>
                <Navbar/>
                <section className='content' id='content'>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/Offre" element={<Offre/>}/>
                        <Route path="/Connection" element={<Connection />}/>
                        <Route path="/Forum" element={<Blog/>}/>
                        <Route path='/Profile' element={<Profile/>}/>
                        <Route path='/Agenda' element={<Agenda />} />
                        <Route path='/Don' element={<Don/>} />
                        <Route path='/Actualite' element={<Actu />} />
                    </Routes>
                </section>
            </BrowserRouter>
        </>
    );
};

export default App;